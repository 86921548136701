<template>
  <page-layout>
    <template v-slot:contents>
      <v-row>
        <v-col cols="12" class="pt-10">
          <v-row justify="center" align="center">
            <div>
              <login-form
                class="login-login-form"
                :employee-no.sync="_employeeNo"
                :password.sync="_password"
                @input-password-keydown-prevent-enter="
                  $emit('input-password-keydown-prevent-enter')
                "
                @login-btn-click="$emit('login-btn-click')"
                ref="loginForm"
              >
              </login-form>
              <div class="pt-1 text-end subtitle-2"> Ver. {{ version }} </div>
            </div>
          </v-row>
          <v-row justify="center" align="center">
            <error-message-panel
              title=""
              :messages="errorMessages"
              class="mb-1 mt-2"
              v-if="errorMessages.length > 0"
            />
          </v-row>
        </v-col>
      </v-row>
    </template>
  </page-layout>
</template>

<script>
  import pageLayout from "@/components/templates/layout/pageLayout.vue";
  import loginForm from "@/components/organisms/login/loginForm.vue";
  import errorMessagePanel from "@/components/molecules/panel/errorMessagePanel.vue";
  export default {
    components: {
      pageLayout,
      loginForm,
      errorMessagePanel,
    },
    data() {
      return {};
    },
    props: {
      employeeNo: {
        type: String,
        default: "",
      },
      password: {
        type: String,
        default: "",
      },
      errorMessages: {
        type: Array,
        default: function () {
          return [];
        },
      },
      version: {
        type: String,
        default: null,
      },
    },
    computed: {
      _employeeNo: {
        get() {
          return this.employeeNo;
        },
        set(value) {
          this.$emit("update:employeeNo", value);
        },
      },
      _password: {
        get() {
          return this.password;
        },
        set(value) {
          this.$emit("update:password", value);
        },
      },
    },
    methods: {
      setDefaultFocus() {
        this.$refs.loginForm.setDefaultFocus();
      },
      setInputPasswordFocus() {
        this.$refs.loginForm.setInputPasswordFocus();
      },
    },
  };
</script>

<style scoped>
  .login-login-form {
    width: 300px;
  }
</style>