<template>
  <login
    :employee-no.sync="employeeNoData"
    :password.sync="passwordData"
    :error-messages="errorMessagesData"
    :version="version"
    @input-password-keydown-prevent-enter="
      inputPasswordKeydownPreventEnterEvent
    "
    @login-btn-click="loginBtnClickEvent"
    ref="login"
  ></login>
</template>

<script>
  import login from "@/components/templates/login/login.vue";
  import typeHelper from "@/common/type.js";
  import { APP } from "@/common/const.js";
  import queryHelper, { QUERY_SORT_KEY } from "@/common/query.js";
  const QUERY_LOGIN_KEY = Object.freeze({
    EMPLOYEE_NO: "i",
    PASSWORD: "xxxx",
  });
  export default {
    components: {
      login,
    },
    data() {
      return {
        employeeNoData: null,
        passwordData: null,

        errorMessagesData: [],
      };
    },
    props: {
      version: {
        type: String,
        default: null,
      },
    },
    computed: {},
    methods: {
      loginBtnClickEvent() {
        let req = {
          no: this.employeeNoData,
          password: this.passwordData,
        };
        this.axios
          .post("/api/login", req)
          .then((res) => {
            if (typeHelper.isNull(this.$route.query.redirect)) {
              window.location.replace(APP.HOME_URL);
            } else {
              window.location.replace(
                decodeURIComponent(this.$route.query.redirect)
              );
            }
          })
          .catch((err) => {
            if (err.apiErrorMessages) {
              this.errorMessagesData = err.apiErrorMessages;
            } else {
              throw err;
            }
            this.passwordData = null;
            this.$refs.login.setInputPasswordFocus();
          });
      },
      inputPasswordKeydownPreventEnterEvent() {
        this.loginBtnClickEvent();
      },
      parseQuery() {
        const query = this.$route.query;
        this.employeeNoData = queryHelper.getValue(
          query,
          QUERY_LOGIN_KEY.EMPLOYEE_NO,
          null
        );
        this.passwordData = queryHelper.getValue(
          query,
          QUERY_LOGIN_KEY.PASSWORD,
          null
        );
      },
      //####################################
      // その他処理
      //####################################
      initializeData() {
        this.parseQuery();
      },
    },
    created: function () {
      this.$emit("show-menu", false);
    },
    destroyed: function () {
      this.$emit("show-menu", true);
    },
    mounted: function () {
      this.$refs.login.setDefaultFocus();
    },
    watch: {
      // コンポーネントの再利用時にデータが再取得＆描画されるよう$routeの変更をウォッチしておく
      // beforeRouteEnterもあるがナビゲーションの前に呼び出されることよりthisが使用できないので$routeのウォッチを採用する
      // immediate: trueによる初回の実行タイミングは beforeCreate と created の間
      $route: {
        immediate: true,
        handler(to, from) {
          // 自身によるルーティングは再描画を行わないようにする。
          if (!typeHelper.isNull(from) && to.name === from.name && to.path !== to.fullPath) {
            return;
          }
          // データの初期化
          this.initializeData();
        },
      },
    },
  };
</script>