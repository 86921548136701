<template>
  <v-card class="elevation-1">
    <v-card-title class="login-card-title text-center">
      ログイン - 勤怠管理システム
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-5 pt-7">
      <v-form>
        <input-text
          label="社員番号"
          fixed-label
          :hide-details="false"
          :text.sync="_employeeNo"
          append-icon="mdi-account"
          @keydown.native.prevent.enter="inputNoKeydownEnterPreventEvent"
          ref="inputEmployeeNo"
        ></input-text>
        <input-text
          label="パスワード"
          fixed-label
          :hide-details="false"
          :text.sync="_password"
          append-icon="mdi-lock"
          type="password"
          @keydown.native.prevent.enter="
            $emit('input-password-keydown-prevent-enter')
          "
          ref="inputPassword"
        ></input-text>
      </v-form>
      <div class="login-btn">
        <btn
          type="primary"
          @btnClick="$emit('login-btn-click')"
          :loading="loading"
          :block="true"
          btn-text="ログイン"
        ></btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import btn from "@/components/atoms/button/btn.vue";
  import inputText from "@/components/atoms/input/inputText.vue";
  export default {
    components: {
      btn,
      inputText,
    },
    data() {
      return {
        loading: false,
      };
    },
    props: {
      employeeNo: {
        type: String,
        default: "",
      },
      password: {
        type: String,
        default: "",
      },
    },
    computed: {
      _employeeNo: {
        get() {
          return this.employeeNo;
        },
        set(value) {
          this.$emit("update:employeeNo", value);
        },
      },
      _password: {
        get() {
          return this.password;
        },
        set(value) {
          this.$emit("update:password", value);
        },
      },
    },
    methods: {
      inputNoKeydownEnterPreventEvent() {
        this.$refs.inputPassword.setFocus();
      },
      setDefaultFocus() {
        this.setInputEmployeeNoFocus();
      },
      setInputEmployeeNoFocus() {
        this.$refs.inputEmployeeNo.setFocus();
      },
      setInputPasswordFocus() {
        this.$refs.inputPassword.setFocus();
      },
    },
  };
</script>

<style scoped>
  .login-card-title {
    background-color: #7cc5e8;
    color: rgba(0, 0, 0, 0.6);
  }
</style>
