<template>
  <v-app dark>
    <v-main>
      <v-container fluid fill-height>
        <router-view :version="version"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
    props: {
      version: {
        type: String,
        default: null,
      },
    },
    computed: {},
    watch: {},
    methods: {},
  };
</script>

<style scoped lang="scss">
  .container {
    padding: 0px;
  }
</style>
