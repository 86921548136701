<template>
  <v-card class="elevation-2 tablet">
    <v-card-title class="login-card-title text-center">
      ログイン - 勤怠管理システム
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-5 py-9">
      <div class="d-flex flex-row">
        <input-text
          label="社員名称"
          fixedLabel
          readonly
          :hide-details="false"
          :text.sync="_employeeName"
          clearable
          append-icon="mdi-account"
          class="mr-2"
          tablet
        ></input-text>
        <icon-btn
          @btnClick="$emit('employee-search-btn-click')"
          type="accountSearch"
          x-large
        ></icon-btn>
      </div>
      <div class="login-btn mt-4">
        <btn
          type="primary"
          @btnClick="$emit('login-btn-click')"
          :loading="loading"
          :block="true"
          btnText="ログイン"
          tablet
          x-large
        ></btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import btn from "@/components/atoms/button/btn.vue";
  import iconBtn from "@/components/atoms/button/iconBtn.vue";
  import inputText from "@/components/atoms/input/inputText.vue";

  export default {
    components: {
      btn,
      iconBtn,
      inputText,
    },
    data() {
      return {
        loading: false,
      };
    },
    props: {
      employeeId: {
        type: String,
        default: "",
      },
      employeeName: {
        type: String,
        default: "",
      },
    },
    computed: {
      _employeeName: {
        get() {
          return this.employeeName;
        },
        set(value) {
          this.$emit("update:employeeId", value);
          this.$emit("update:employeeName", value);
        },
      },
    },
    methods: {},
  };
</script>

<style scoped lang="scss">
  .tablet {
    .login-card-title {
      font-size: 1.6rem;
      line-height: 2.9rem;
    }
  }

  .login-card-title {
    background-color: hsl(113, 70%, 70%);
    color: rgba(0, 0, 0, 0.6);
  }
</style>
