/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue'
import vuetify from '@/plugins/vuetify.js';
import axios from '@/plugins/axios.js'
import veeValidate from '@/plugins/veeValidate';
import router from '@/router/auth.js';
import auth from "@/pages/auth.vue";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@/sass/app.scss'
import _ from 'lodash';

new Vue({
    vuetify,
    router,
    el: "#app",
    components: {
        "app": auth,
    }
})
