<template>
  <proxy-login
    :employee-id.sync="employeeIdData"
    :employee-name.sync="employeeNameData"
    :version="version"
    :error-messages="errorMessagesData"
    :employee-search-dialog-show="showSearchDialogData"
    @employee-search-btn-click="employeeSearchBtnClickEvent"
    @employee-search-dialog-ok-btn-click="employeeSearchDialogOKBtnClickEvent"
    @employee-search-dialog-cancel-btn-click="
      employeeSearchDialogCancelBtnClickEvent
    "
    @login-btn-click="loginBtnClickEvent"
    @logout-btn-click="logoutBtnClickEvent"
  ></proxy-login>
</template>

<script>
  import proxyLogin from "@/components/templates/login/proxyLogin.vue";
  import typeHelper from "@/common/type.js";
  import { APP } from "@/common/const.js";
  export default {
    components: {
      proxyLogin,
    },
    data() {
      return {
        employeeIdData: null,
        employeeNameData: null,
        employeeNoData: null,

        errorMessagesData: [],
        showSearchDialogData: false,
      };
    },
    props: {
      version: {
        type: String,
        default: null,
      },
    },
    computed: {},
    methods: {
      employeeSearchBtnClickEvent() {
        this.showSearchDialogData = true;
      },
      employeeSearchDialogOKBtnClickEvent(item) {
        this.employeeIdData = item.value;
        this.employeeNameData = item.text;
        this.employeeNoData = item.no;
        this.showSearchDialogData = false;
      },
      employeeSearchDialogCancelBtnClickEvent() {
        this.showSearchDialogData = false;
      },
      logoutBtnClickEvent() {
        this.axios
          .delete("/api/proxy-login-mode")
          .then((res) => {
            window.location.replace(APP.HOME_URL);
          })
          .catch((err) => {
            if (err.apiErrorMessages) {
              this.errorMessagesData = err.apiErrorMessages;
            } else {
              throw err;
            }
          });
      },
      loginBtnClickEvent() {
        let req = {
          no: this.employeeNoData,
        };
        this.axios
          .post("/api/login", req)
          .then((res) => {
            if (typeHelper.isNull(this.$route.query.redirect)) {
              window.location.replace(APP.HOME_URL);
            } else {
              window.location.replace(
                decodeURIComponent(this.$route.query.redirect)
              );
            }
          })
          .catch((err) => {
            if (err.apiErrorMessages) {
              this.errorMessagesData = err.apiErrorMessages;
            } else {
              throw err;
            }
          });
      },
    },
    created: function () {
      this.$emit("show-menu", false);
    },
    destroyed: function () {
      this.$emit("show-menu", true);
    },
  };
</script>
